import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import axiosInstance from '../../config/api'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  menu: {
    width: 200,
  },
}))

const genres = [
  {
    value: 'kanguka',
    label: 'Kanguka',
  },
  {
    value: 'testimony',
    label: 'Testimony',
  },
  {
    value: 'testimony-video',
    label: 'Testimony-Video',
  },
  {
    value: 'teaching',
    label: 'Teaching',
  },
  {
    value: 'teaching-video',
    label: 'Teaching-Video',
  },
]

const status = [
  {
    value: 'offline',
    label: 'Offline',
  },
  {
    value: 'online',
    label: 'Online',
  },
]

const featured = [
  {
    value: 'false',
    label: 'NO',
  },
  {
    value: 'true',
    label: 'YES',
  },
]

const regexLookup = {
  fr: '^Kanguka de Samedi',
  en: '^Kanguka of Saturday',
  ki: '^Kanguka yo ku wa gatandatu',
}

const ProgramEditDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const [values, setValues] = useState({
    open: false,
  })
  const [isVideoItem, setVideoItem] = useState(false)

  const [tags, setTags] = useState([])
  const [inputTagValue, setInputTagValue] = useState('')

  const handleChange = (name) => (event) => {
    if (name === 'date') {
      const newDateTime = moment(event).format('YYYY-MM-DD HH:mm')
      setValues({...values, [name]: newDateTime})
    } else if (
      (name === 'genre' && event.target.value === 'teaching-video') ||
      (name === 'genre' && event.target.value === 'testimony-video')
    ) {
      setVideoItem(true)
      setValues({...values, [name]: event.target.value})
    } else if (
      (name === 'genre' && event.target.value !== 'teaching-video') ||
      (name === 'genre' && event.target.value !== 'testimony-video')
    ) {
      setVideoItem(false)
      setValues({...values, [name]: event.target.value})
    } else {
      setValues({...values, [name]: event.target.value})
    }
  }

  const fetchProgramsTags = async (lang) => {
    const response = await axiosInstance.get(
      `api/v2/program/currenttag?lang=${lang}`,
    )
    setTags(response.data)
  }

  useEffect(() => {
    if (values.open && values.lang) {
      fetchProgramsTags(values.lang)
    }
  }, [values.open, values.lang])

  useEffect(() => {
    if (tags && tags.length > 0) {
      setInputTagValue(tags[0]?.description)
      setValues({...values, tag: tags[0]?.description})
    }
  }, [tags])

  const handleClose = () => {
    setValues({...values, open: false})
  }

  const handleOpen = (payload) => {
    setValues({
      ...values,
      description: payload.description,
      audio: payload.audio,
      date: payload.date,
      genre: payload.genre,
      lang: payload.lang,
      status: payload.status,
      videoUrl: payload.videoUrl,
      audioSize: !payload.audioSize ? 0 : payload.audioSize,
      shortUrl: !payload.shortUrl ? '' : payload.shortUrl,
      id: payload._id,
      featured: !payload.featured ? 'NO' : payload.featured,
      tag: !payload.tag ? '' : payload.tag,
      open: true,
    })

    // Immediately fetch tags when opening dialog
    if (payload.lang) {
      fetchProgramsTags(payload.lang)
    }

    setVideoItem(
      payload.genre === 'teaching-video' || payload.genre === 'testimony-video',
    )
  }

  const handleConfirm = () => {
    if (isVideoItem) {
      props.handleConfirm(values, null)
    } else {
      delete values.videoUrl
      props.handleConfirm(values, document.getElementById('audioFile').files[0])
    }

    setValues({...values, open: false})
    setVideoItem(false)
  }

  useImperativeHandle(ref, () => ({
    openDialog(payload) {
      handleOpen(payload)
    },
  }))

  return (
    <Dialog
      open={values.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
      <DialogContent>
        <form className={classes.container} autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TextField
              id="description"
              label="Description"
              style={{margin: 8}}
              fullWidth
              margin="normal"
              value={values.description}
              onChange={handleChange('description')}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {isVideoItem && (
              <TextField
                id="videoUrl"
                label="Video Url"
                style={{margin: 8}}
                fullWidth
                margin="normal"
                value={values.videoUrl}
                onChange={handleChange('videoUrl')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            {!isVideoItem && (
              <TextField
                disabled
                id="audio"
                label="Audio"
                style={{margin: 8}}
                fullWidth
                margin="normal"
                value={values.audio}
                onChange={handleChange('audio')}
                InputLabelProps={{
                  shrink: true,
                  readOnly: true,
                }}
              />
            )}
            {!isVideoItem && <Input id="audioFile" fullWidth type="file" />}
            <KeyboardDatePicker
              margin="normal"
              id="date"
              label="Date"
              format="dd/MM/yyyy"
              value={values.date}
              className={classes.textField}
              onChange={handleChange('date')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time"
              label="Time"
              value={values.date}
              className={classes.textField}
              onChange={handleChange('date')}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
            <TextField
              id="genre"
              select
              label="Genre"
              className={classes.textField}
              value={values.genre}
              onChange={handleChange('genre')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal">
              {genres.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="status"
              select
              label="Status"
              className={classes.textField}
              value={values.status}
              onChange={handleChange('status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal">
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="featured"
              select
              label="Featured"
              defaultValue=""
              className={classes.textField}
              value={values.featured}
              onChange={handleChange('featured')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal">
              {featured.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {!isVideoItem && (
              <TextField
                disabled
                id="audioSize"
                label="AudioSize"
                value={values.audioSize}
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
            {values.genre === 'kanguka' && (
              <TextField
                disabled
                id="tag"
                label="Today's Category"
                style={{margin: 8}}
                fullWidth
                margin="normal"
                value={tags.length > 0 ? tags[0].description : ''}
                InputLabelProps={{
                  shrink: true,
                  readOnly: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
            <TextField
              disabled
              id="shortUrl"
              label="ShortUrl"
              style={{margin: 8}}
              fullWidth
              margin="normal"
              value={values.shortUrl}
              InputLabelProps={{
                shrink: true,
                readOnly: true,
              }}
            />
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ProgramEditDialog
